'use client';

import { motion, Variants } from 'motion/react';
import { useState } from 'react';

import { cn } from '@/utils/cn';

import { ArrowRight } from './arrow-right';

const MotionArrowRight = motion.create(ArrowRight);

const buttonVariants: Variants = {
    hover: {
        width: 120,
    },
    rest: {
        width: 56,
    },
};

type ArrowButtonProps = {
    className?: string;
    isHovered?: boolean;
};

export const ArrowButton = ({
    className,
    isHovered: isHoveredProp = false,
}: ArrowButtonProps) => {
    const [isHovered, setIsHovered] = useState(false);

    const showViewText = isHoveredProp || isHovered;

    return (
        <motion.div
            whileHover="hover"
            transition={{ duration: 0.25, type: 'spring' }}
            animate={showViewText ? 'hover' : 'rest'}
            initial="rest"
            variants={buttonVariants}
            onHoverStart={() => setIsHovered(true)}
            onHoverEnd={() => setIsHovered(false)}
            className={cn(
                `
                  mt-8 inline-flex h-[56px] items-center justify-center
                  rounded-full bg-primary-limegreen align-middle
                `,
                className,
            )}
        >
            {showViewText ? (
                <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className={`
                      px-4 py-2 text-2xl font-medium text-primary-black

                      md:tracking-tighter
                    `}
                >
                    View
                </motion.span>
            ) : (
                <MotionArrowRight
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                />
            )}
        </motion.div>
    );
};
