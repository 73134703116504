import { forwardRef, SVGProps } from 'react';

import { cn } from '@/utils/cn';

export const ArrowRight = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>(
    ({ className, ...props }, ref) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            viewBox="0 0 56 56"
            {...props}
            className={cn('fill-primary-black', className)}
            ref={ref}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M44.2532 27L9.00098 27V29L44.254 29L27.466 45.7879L28.8802 47.2022L47.3749 28.7075V27.2933L28.8802 8.79862L27.466 10.2128L44.2532 27Z"
                fill="#313131"
            />
        </svg>
    ),
);

ArrowRight.displayName = 'ArrowRight';
