import { useEffect, useState } from 'react';

export const useDelayedValue = <T = unknown>({
    delay,
    initialValue,
    value,
}: {
    delay: number;
    initialValue?: T;
    value: T;
}) => {
    const [delayedValue, setDelayedValue] = useState<T | undefined>(
        initialValue,
    );

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDelayedValue(value);
        }, delay);

        return () => {
            clearTimeout(timeout);
        };
    }, [value, delay]);

    return delayedValue;
};
