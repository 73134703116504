import { cn } from '@/utils/cn';

import { IconProps } from './types';

export const Facebook = ({ className }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="57"
        height="56"
        viewBox="0 0 57 56"
        fill="none"
        className={cn('stroke-primary-white', className)}
    >
        <path
            d="M28.333 40C21.7056 40 16.333 34.6274 16.333 28C16.333 21.3726 21.7056 16 28.333 16C34.9604 16 40.333 21.3726 40.333 28C40.333 34.6274 34.9604 40 28.333 40ZM28.333 40V25.6C28.333 24.2745 29.4075 23.2 30.733 23.2H32.533M24.133 30.4H32.533"
            strokeWidth="2"
        />
    </svg>
);
