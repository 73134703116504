import { Route } from 'next';
import Link from 'next/link';

import { Facebook } from './icons/facebook';
import { Instagram } from './icons/instagram';
import { LinkedIn } from './icons/linkedin';

const SOCIAL_MEDIA = [
    {
        icon: LinkedIn,
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/company/swing-development/',
    },
    {
        icon: Instagram,
        name: 'Instagram',
        url: 'https://instagram.com/swingdev',
    },
    {
        icon: Facebook,
        name: 'Facebook',
        url: 'https://www.facebook.com/swingdev',
    },
];

export const SocialMediaLinks = () => (
    <div className="flex items-center justify-center gap-1">
        {SOCIAL_MEDIA.map(({ icon: Icon, name, url }) => (
            <Link
                key={name}
                href={url as Route}
                className={`
                  group rounded-sm transition-all

                  hover:rounded-full hover:bg-primary-limegreen
                `}
            >
                <Icon
                    className={`
                      transition-all

                      group-hover:stroke-primary-black
                    `}
                />
            </Link>
        ))}
    </div>
);
