import { Route } from 'next';

type Link = { href: Route; title: string };

export const LINKS: Link[] = [
    { href: '/about', title: 'About' },
    { href: '/culture', title: 'Culture' },
    { href: '/careers', title: 'Careers' },
    { href: '/work', title: 'Work' },
    { href: 'https://medium.com/swinginc', title: 'Blog' },
];

export const HOME_LINK: Link = {
    href: '/',
    title: 'Home',
};

export const OPEN_POSITION_LINK: Link = {
    href: '/careers/open-positions',
    title: 'Open positions',
};
