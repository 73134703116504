import { Route } from 'next';
import Link from 'next/link';

const LEGAL_LINKS = [
    {
        name: 'Cookies Policy',
        url: '/cookies-policy',
    },
    {
        name: 'Privacy Policy',
        url: '/privacy-policy',
    },
];

export const LegalLinks = () => (
    <div
        className={`
          flex flex-col items-center justify-center gap-6

          md:flex-row
        `}
    >
        {LEGAL_LINKS.map(({ name, url }) => (
            <Link
                key={name}
                href={url as Route}
                className={`
                  typography-body4 h-8 whitespace-nowrap border-b
                  border-b-primary-gray text-primary-gray
                `}
            >
                {name}
            </Link>
        ))}
    </div>
);
