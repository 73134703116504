import { motion, stagger, useAnimate } from 'motion/react';
import { PropsWithChildren, useEffect } from 'react';

const MovingDiv = ({ children }: PropsWithChildren) => (
    <motion.div initial={{ y: 200 }}>{children}</motion.div>
);

export const HeaderTextContainer = () => {
    const [scope, animate] = useAnimate();

    useEffect(() => {
        void animate(
            'div',
            {
                y: 0,
            },
            {
                delay: stagger(0.5),
                ease: 'easeOut',
            },
        );
    }, [animate]);

    return (
        <>
            <motion.div
                className={`
                  inset-x-0 top-10 z-10 mt-32 w-full whitespace-nowrap
                  text-center
                `}
                layout="position"
                transition={{
                    delay: 0.5,
                    duration: 0.6,
                    type: 'tween',
                }}
                whileInView={{
                    opacity: 1,
                    transition: {
                        duration: 1,
                    },
                }}
                viewport={{ once: true }}
            >
                <motion.h2
                    ref={scope}
                    className={`
                      typography-h2 text-[4.5rem] leading-[4rem]

                      lg:text-[11rem] lg:leading-[9rem]

                      md:text-[9rem] md:leading-[7rem]
                    `}
                >
                    <div
                        className={`
                          overflow-hidden

                          lg:mt-4
                        `}
                    >
                        <MovingDiv>We share</MovingDiv>
                    </div>
                    <div
                        className={`
                          overflow-hidden

                          lg:mt-4
                        `}
                    >
                        <MovingDiv>common</MovingDiv>
                    </div>

                    <div
                        className={`
                          overflow-hidden

                          lg:mt-4
                        `}
                    >
                        <MovingDiv>values</MovingDiv>
                    </div>
                </motion.h2>
            </motion.div>
        </>
    );
};
