import { motion } from 'motion/react';
import { PropsWithChildren } from 'react';

import { cn } from '@/utils/cn';

type Props = PropsWithChildren & {
    className?: string;
    delay: number;
    onToggle: () => void;
};

const variants = {
    closed: { opacity: 0, y: '20%' },
    opened: { opacity: 1, y: 0 },
};

export const FadingItem = ({ children, className, delay, onToggle }: Props) => (
    <motion.li
        initial={'closed'}
        animate={'opened'}
        variants={variants}
        transition={{ delay, duration: 0.3 }}
        className={cn(`group relative flex justify-center`, className)}
        onClick={onToggle}
    >
        {children}
    </motion.li>
);
