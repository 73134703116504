import { motion } from 'motion/react';

type Props = {
    isExpanded: boolean;
};

const variants = {
    closed: { height: 0 },
    opened: { height: '100vh' },
};

export const AnimatedOverlay = ({ isExpanded }: Props) => (
    <motion.div
        initial={'closed'}
        variants={variants}
        animate={isExpanded ? 'opened' : 'closed'}
        transition={{ delay: isExpanded ? 0 : 0.1 }}
        className={`absolute inset-0 z-0 overflow-hidden bg-primary-black`}
    />
);
