import {
    useAnimate,
    useMotionValue,
    useMotionValueEvent,
    useScroll,
} from 'motion/react';

type Props = {
    headerHeight: number;
    isLocked?: boolean;
};

const HIDE_DURATION = 0.2;
const OPEN_POSITION = 0;

export const useMenuHide = ({ headerHeight, isLocked }: Props) => {
    const y = useMotionValue(0);
    const { scrollY } = useScroll();
    const [, animate] = useAnimate();

    useMotionValueEvent(scrollY, 'change', (latest) => {
        if (isLocked) {
            return;
        }

        const previous = scrollY.getPrevious() ?? 0;
        const isScrollingDown = latest > previous;
        const isDocked = isScrollingDown && latest < headerHeight;
        const hiddenPosition = -Math.min(headerHeight, latest);
        const currentPosition = isScrollingDown
            ? hiddenPosition
            : OPEN_POSITION;

        // when user is scrolling down in a range of header height and reverse scrolling direction
        // header will immediately jump to the top of the screen making it look choppy
        // this is solution to prevent this effect
        const isReturningToDock =
            latest < headerHeight && isScrollingDown && y.get() + latest > 10;
        const distanceToDock = y.get() + latest;
        const durationToDock = Math.min(
            HIDE_DURATION,
            distanceToDock / (headerHeight / HIDE_DURATION),
        );

        void animate(y, currentPosition, {
            duration: isReturningToDock
                ? durationToDock
                : isDocked
                  ? 0
                  : HIDE_DURATION,
        });
    });

    return y;
};
