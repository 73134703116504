'use client';

import { useInView } from 'motion/react';
import { useRef } from 'react';

import { cn } from '@/utils/cn';

import { ImagesProps } from '../../types';
import { AnimatedImage } from '../animated-image';

export const ImagesContainer = ({ images, isEven }: ImagesProps) => {
    const scrollRef = useRef(null);
    const isInView = useInView(scrollRef, { amount: 0.25, once: true });

    return (
        <figure
            ref={scrollRef}
            className={cn(
                `
                  grid grid-cols-[1fr_2fr] place-content-center gap-2.5

                  lg:basis-1/2 lg:gap-4
                `,
                isEven ? 'lg:pr-5' : 'lg:pl-5',
            )}
        >
            {images.map((image) => (
                <AnimatedImage
                    {...image}
                    isInView={isInView}
                    key={image.id}
                />
            ))}
        </figure>
    );
};
