import { PropsWithChildren } from 'react';

import { cn } from '@/utils/cn';

type Props = PropsWithChildren & {
    as?: keyof React.JSX.IntrinsicElements;
    className?: string;
    fullWidth?: boolean;
    id?: string;
    verticalSpacing?: boolean;
};

export const Container = ({
    as,
    children,
    className,
    fullWidth,
    id,
    verticalSpacing,
}: Props) => {
    const Tag = as ?? 'div';

    return (
        <Tag
            id={id}
            className={cn(
                { 'py-20 xl:py-32': verticalSpacing },
                fullWidth
                    ? 'w-full'
                    : `
                      w-full px-6

                      lg:px-16

                      sm:px-10

                      xl:px-24

                      xxl:container xxl:mx-auto
                    `,
                className,
            )}
        >
            {children}
        </Tag>
    );
};
