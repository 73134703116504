import Image from 'next/image';
import Link from 'next/link';

export const SwingdevLogoResponsive = () => (
    <Link href={'/'}>
        <Image
            src="/swingdev-emblem.svg"
            width={48}
            height={48}
            alt="SwingDev"
            className={`
              block

              sm:hidden
            `}
        />
        <Image
            src="/swingdev-wordmark.svg"
            width={165}
            height={38}
            alt="SwingDev"
            className={`
              hidden

              sm:block

              xxl:hidden
            `}
        />
        <Image
            src="/swingdev-logo.svg"
            width={201}
            height={41}
            alt="SwingDev"
            className={`
              hidden

              xxl:block
            `}
        />
    </Link>
);
