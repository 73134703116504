import * as motion from 'motion/react-client';

interface SectionHeaderTextProps {
    delay: number;
    text: string;
}

export const SectionBottomText = ({ delay, text }: SectionHeaderTextProps) => (
    <motion.div
        initial={{
            opacity: 0,
        }}
        whileInView={{
            opacity: 1,
            transition: {
                delay,
                duration: 1,
            },
        }}
        viewport={{ once: true }}
    >
        <p
            className={`
              typography-body1 text-xl font-bold

              lg:text-4xl

              md:text-3xl
            `}
        >
            {text}
        </p>
    </motion.div>
);
