import * as motion from 'motion/react-client';

import { cn } from '@/utils/cn';

import { SectionBottomText } from '../section-bottom-text';

export const AnimatedBottomText = () => (
    <motion.div
        className={cn(`absolute mx-auto inline-block size-fit text-center`)}
        initial={{
            left: 0,
            right: 0,
            top: '94rem',
        }}
    >
        <SectionBottomText
            text={'Our values shape how we work'}
            delay={0}
        />
        <SectionBottomText
            text={'and what we expect. They highlight'}
            delay={1}
        />
        <SectionBottomText
            text={'what we give back as a company.'}
            delay={2}
        />
    </motion.div>
);
