'use client';

import { useInView } from 'motion/react';
import Image from 'next/image';
import { HTMLAttributes, ImgHTMLAttributes, useRef } from 'react';

import { useDelayedValue } from '@/hooks/use-delayed-value';
import { cn } from '@/utils/cn';

import { useContainerStyle } from './hooks/use-container-style';
import { useImageLoad } from './hooks/use-image-load';
import { useImageSize } from './hooks/use-image-size';

type Props = {
    delay?: number;
    layoutOffsetAmount?: [number, number];
    pointerOffsetRadius?: number;
    pointerOffsetStrength?: number;
    shaded?: boolean;
} & HTMLAttributes<HTMLDivElement> &
    Required<Pick<ImgHTMLAttributes<HTMLImageElement>, 'src' | 'alt'>>;

/**
 *
 * Renders a hero image with a zoom effect on hover or touch.
 * Includes a loading state, and a layout and pointer offset, as well as a
 * handling in view trigger, which delays the image load until it's in view.
 *
 * @param layoutOffsetAmount - The amount of layout offset to apply to the image, see useContainerStyle.
 * @param pointerOffsetStrength - The passed to usePointerOffset, see useContainerStyle.
 * @param pointerOffsetRadius - The radius of the pointer offset, in pixels, see useContainerStyle.
 * @param shaded - Whether to apply a shaded effect to the image when it's not expanded.
 * @param delay - The delay in milliseconds to wait before loading the image.
 */

export const HeroImage = ({
    alt,
    className,
    delay = 0,
    layoutOffsetAmount,
    pointerOffsetRadius,
    pointerOffsetStrength,
    shaded,
    src,
    ...props
}: Props) => {
    const container = useRef<HTMLDivElement>(null);

    const isHidden = useDelayedValue({
        delay,
        initialValue: delay > 0,
        value: false,
    });

    // Handle the image resizing

    const { isExpanded, toggle } = useImageSize();

    // Detect image orientation on load and handle loading state

    const { handleImageLoad, isLoaded } = useImageLoad();

    // Container style, including the layout and pointer offsets

    const { containerStyle } = useContainerStyle({
        layoutOffsetConfig: {
            amountX: layoutOffsetAmount?.[0],
            amountY: layoutOffsetAmount?.[1],
            input: src,
        },
        pointerOffsetConfig: {
            radius: pointerOffsetRadius,
            ref: container,
            strength: pointerOffsetStrength,
        },
    });

    // Trigger the image load when it's in view

    const isInView = useInView(container, { once: true });

    // Render nothing if there's no source

    const shouldRenderImage = !isHidden && isInView && src;

    return (
        <div
            className={cn(
                `
                  group absolute flex max-h-[640px] max-w-screen-sm items-center
                  justify-center will-change-transform
                `,
                className,
            )}
            ref={container}
            style={containerStyle}
            {...props}
        >
            {shouldRenderImage && (
                <div
                    className={cn(
                        `
                          pointer-events-auto relative size-full cursor-pointer
                          overflow-hidden rounded-[320px] transition-all
                          duration-1000 ease-in-out will-change-transform
                        `,
                        isLoaded
                            ? {
                                  'brightness-75 group-hover:brightness-100':
                                      shaded && !isExpanded,
                                  'scale-100 rounded-3xl': isExpanded,
                                  'scale-110': isExpanded,
                                  'scale-[0.9] group-hover:scale-[0.95]':
                                      !isExpanded,
                              }
                            : 'invisible aspect-square scale-0 brightness-0',
                    )}
                    onClick={toggle}
                >
                    <Image
                        alt={alt}
                        src={src}
                        onLoad={handleImageLoad}
                        fill
                        loading="lazy"
                        className={`
                          pointer-events-none scale-110 object-cover
                          transition-transform duration-1000 ease-in-out

                          group-hover:scale-100
                        `}
                    />
                </div>
            )}
        </div>
    );
};
