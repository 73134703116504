import { ReactEventHandler, useCallback, useState } from 'react';

/**
 * A hook that provides a function to handle the image load event and a boolean that indicates whether the image is loaded.
 *
 * @param onLoad - a function that will be called when the image is loaded. It will receive the image element as an argument.
 *
 * @returns handleImageLoad - a function that should be passed to the `onLoad` prop of the `img` element.
 * @returns isLoaded - a boolean that indicates whether the image is loaded.
 */

type Props = {
    onLoad?: (image: HTMLImageElement) => void;
};

export const useImageLoad = (props?: Props) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const handleImageLoad = useCallback<ReactEventHandler<HTMLImageElement>>(
        (event) => {
            setIsLoaded(true);

            if (props?.onLoad) {
                props.onLoad(event.currentTarget);
            }
        },
        [props],
    );

    return {
        handleImageLoad,
        isLoaded,
    };
};
