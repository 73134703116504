import(/* webpackMode: "eager" */ "/app/node_modules/motion/dist/es/motion/lib/react-client.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/public/home/office-section/office-1.jpeg");
;
import(/* webpackMode: "eager" */ "/app/public/home/office-section/office-2.png");
;
import(/* webpackMode: "eager" */ "/app/public/home/office-section/office-3.png");
;
import(/* webpackMode: "eager" */ "/app/public/home/office-section/office-4.jpeg");
;
import(/* webpackMode: "eager" */ "/app/public/home/office-section/office-5.jpeg");
;
import(/* webpackMode: "eager" */ "/app/public/home/split-view-section/split-view-section-1-1.png");
;
import(/* webpackMode: "eager" */ "/app/public/home/split-view-section/split-view-section-1-2.png");
;
import(/* webpackMode: "eager" */ "/app/public/home/split-view-section/split-view-section-1-3.png");
;
import(/* webpackMode: "eager" */ "/app/public/home/split-view-section/split-view-section-2-1.png");
;
import(/* webpackMode: "eager" */ "/app/public/home/split-view-section/split-view-section-2-2.png");
;
import(/* webpackMode: "eager" */ "/app/public/home/split-view-section/split-view-section-2-3.jpg");
;
import(/* webpackMode: "eager" */ "/app/public/home/split-view-section/split-view-section-3.png");
;
import(/* webpackMode: "eager" */ "/app/src/app/(home)/_components/hero-section/hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ImagesContainer"] */ "/app/src/app/(home)/_components/split-view-section/components/images-container/images-container.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ValuesSection"] */ "/app/src/app/(home)/_components/values-section/values-section.tsx");
