export const CloseIcon = () => (
    <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="fill-primary-black"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.2372 12.9977L25.1082 2.27862L23.704 0.854491L12.8129 11.5934L2.23723 1.16554L0.833009 2.58968L11.3886 12.9977L0.833009 23.4058L2.23723 24.8299L12.8129 14.4021L23.704 25.141L25.1082 23.7168L14.2372 12.9977Z"
        />
    </svg>
);
