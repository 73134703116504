import { useMemo } from 'react';

import { useLayoutOffset } from './use-layout-offset';
import { usePointerOffset } from './use-pointer-offset';

/**
 *
 * @param layoutOffsetAmount - The amount of layout offset to apply to the image, [%x, %y]
 * @param pointerOffsetStrength - Strength of the pointer offset effect
 * @param ref - The reference to the container element
 * @param src - The source of the image, used as the input for the predictable random for layout offset
 *
 * @returns The container style object to apply to the container element
 */
export const useContainerStyle = ({
    layoutOffsetConfig,
    pointerOffsetConfig,
}: {
    layoutOffsetConfig: Parameters<typeof useLayoutOffset>[0];
    pointerOffsetConfig: Parameters<typeof usePointerOffset>[0];
}) => {
    const {
        layoutOffset: [layoutOffsetX, layoutOffsetY],
    } = useLayoutOffset(layoutOffsetConfig);

    const {
        pointerOffset: [pointerOffsetX, pointerOffsetY],
    } = usePointerOffset(pointerOffsetConfig);

    const style = useMemo(
        () => ({
            transform: `translate(${100 * parseNumberToFixed(layoutOffsetX + pointerOffsetX)}%, ${
                100 * parseNumberToFixed(layoutOffsetY + pointerOffsetY)
            }%)`,
        }),
        [layoutOffsetX, layoutOffsetY, pointerOffsetX, pointerOffsetY],
    );

    return { containerStyle: style };
};

const parseNumberToFixed = (value: number, fractionDigits = 5) =>
    Number.parseFloat(value.toFixed(fractionDigits));
