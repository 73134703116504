'use client';

import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';

import { Container } from '@/components/container';

import { ArrowButton } from '../arrow-button';
import { ButtonHippoCompany } from '../button-hippo-company/button-hippo-company';
import { ColorfulDivider } from '../colorful-divider';
import { Divider } from './components/dividers';
import { FloatingBadges } from './components/floating-badges';
import { FooterNavigation } from './components/footer-navigation';
import { LegalLinks } from './components/legal';
import { SocialMediaLinks } from './components/social-media';

export const Footer = () => {
    const [isSeeOpenPositionsHovered, setIsSeeOpenPositionsHovered] =
        useState(false);

    return (
        <footer>
            <ColorfulDivider />
            <Container>
                <div
                    className={`
                      grid grid-cols-1 gap-12 py-16 text-center

                      xl:grid-cols-2 xl:gap-4 xl:text-left
                    `}
                >
                    <FloatingBadges />
                    <Link
                        href="/careers"
                        onPointerEnter={() =>
                            setIsSeeOpenPositionsHovered(true)
                        }
                        onPointerLeave={() =>
                            setIsSeeOpenPositionsHovered(false)
                        }
                        className="xl:-order-1"
                    >
                        <h3
                            className={`
                              typography-h3 flex flex-col items-center
                              whitespace-nowrap

                              xl:typography-h4 xl:flex-row xl:items-end
                            `}
                        >
                            <div className="flex flex-col">
                                Join us!
                                <span
                                    className={`
                                      hidden

                                      xl:inline-block
                                    `}
                                >
                                    See open positions
                                </span>
                            </div>
                            <ArrowButton
                                isHovered={isSeeOpenPositionsHovered}
                                className={`
                                  ml-2 mt-2

                                  xl:ml-6
                                `}
                            />
                        </h3>
                    </Link>
                </div>
            </Container>
            <ColorfulDivider />
            <Container
                className={`
                  gap-16 pb-5 pt-10

                  max-md:text-center
                `}
            >
                <div
                    className={`
                      flex flex-col items-center justify-between gap-10
                      self-stretch

                      xl:flex-row xl:gap-0
                    `}
                >
                    <Image
                        src="/swingdev-wordmark.svg"
                        alt="Swing Dev Wordmark"
                        width={180}
                        height={40}
                    />
                    <FooterNavigation />
                    <SocialMediaLinks />
                    <ButtonHippoCompany />
                </div>
                <Divider />
                <div
                    className={`
                      flex flex-col items-center justify-between gap-5
                      self-stretch pb-5

                      xl:flex-row xl:gap-0
                    `}
                >
                    <LegalLinks />
                    <p className="typography-body4 h-8 text-primary-gray">
                        With ♡ from Warsaw
                    </p>
                    <p className="typography-body4 h-8 text-primary-gray">
                        ©2025 SwingDev – a hippo company.
                        All&nbsp;rights&nbsp;reserved.
                    </p>
                </div>
            </Container>
        </footer>
    );
};
