import { ValueBadge } from '@/components/value-badge';

const badges = [
    {
        color: 'orange',
        initialPosition: {
            rotate: 14,
            x: '25%',
            y: 45,
        },
        text: '#WorkSmart',
    },
    {
        color: 'green',
        initialPosition: {
            rotate: -8,
            x: 0,
            y: 15,
        },
        text: '#GotYourBack',
    },
    {
        color: 'pink',
        initialPosition: {
            rotate: 8,
            x: '87%',
            y: 15,
        },
        text: '#BeKind',
    },
    {
        color: 'violet',
        initialPosition: {
            rotate: -18,
            x: '60%',
            y: 40,
        },
        text: '#BeProud',
    },
    {
        color: 'yellow',
        initialPosition: {
            rotate: 6,
            x: '40%',
            y: 0,
        },
        text: '#SpreadGoodVibes',
    },
] as const;

export const FloatingBadges = () => (
    <div
        className={`
          relative min-h-[130px] w-[90%] justify-self-end

          lg:max-w-[475px]

          xl:-right-20
        `}
    >
        {badges.map(({ color, initialPosition, text }) => (
            <ValueBadge
                key={color}
                color={color}
                initialRotate={initialPosition.rotate}
                initialY={initialPosition.y}
                initialX={initialPosition.x}
                bottomBoundary={170}
                topBoundary={-20}
            >
                {text}
            </ValueBadge>
        ))}
    </div>
);
