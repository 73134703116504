'use client';

import { HTMLAttributes, useRef } from 'react';

import { cn } from '@/utils/cn';

import { useMovementHandler } from './hooks/use-movement-handler';
import { useTypingEffect } from './hooks/use-typing-effect';

export const Badge = ({
    children,
    className,
    color = 'green',
    movementConfig,
    typingEffectConfig,
    ...props
}: {
    children: string;
    color?: 'green' | 'orange' | 'pink' | 'violet' | 'yellow';
    movementConfig?: Omit<Parameters<typeof useMovementHandler>[0], 'ref'>;
    typingEffectConfig?: Omit<Parameters<typeof useTypingEffect>[0], 'text'>;
} & Omit<HTMLAttributes<HTMLDivElement>, 'children'>) => {
    const ref = useRef<HTMLDivElement>(null);
    const { currentText } = useTypingEffect({
        text: children,
        ...typingEffectConfig,
    });
    const { handleClick } = useMovementHandler({ ref, ...movementConfig });

    return (
        <div
            ref={ref}
            onClick={handleClick}
            onMouseEnter={handleClick}
            className={cn(
                `absolute transition-transform duration-300 ease-in-out`,
                className,
            )}
            {...props}
        >
            <div
                className={cn(
                    `
                      typography-body4 pointer-events-auto absolute
                      -translate-x-1/2 scale-100 cursor-pointer rounded-full p-4
                      py-2 text-lg font-bold tracking-tighter text-primary-black
                      transition-transform duration-700 ease-out

                      hover:scale-125

                      lg:px-8 lg:py-4 lg:text-2xl

                      md:px-5 md:py-3 md:text-xl

                      xl:px-[min(4vh,2rem)] xl:py-[min(1vh,1rem)]
                      xl:text-[min(3vh,24px)]
                    `,
                    {
                        'bg-primary-green': color === 'green',
                        'bg-primary-orange': color === 'orange',
                        'bg-primary-pink': color === 'pink',
                        'bg-primary-violet': color === 'violet',
                        'bg-primary-yellow': color === 'yellow',
                        'scale-0': !currentText,
                    },
                )}
            >
                {currentText}
            </div>
        </div>
    );
};
