import { motion } from 'motion/react';

import { CloseIcon } from './assets/close-icon';

type Props = {
    isExpanded: boolean;
    onToggle: () => void;
};

export const ButtonExpandMenu = ({ isExpanded, onToggle }: Props) => (
    <motion.button
        onClick={onToggle}
        initial={{ maxWidth: '100px' }}
        animate={{
            backgroundColor: isExpanded ? '#fff' : '#ffffff00',
            color: isExpanded ? '#111' : '#fff',
            maxWidth: isExpanded ? '58px' : '100px',
        }}
        className={`relative rounded-full border p-4 font-medium`}
    >
        <motion.span animate={{ opacity: isExpanded ? 0 : 1 }}>
            Menu
        </motion.span>
        <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: isExpanded ? 1 : 0 }}
            className={`
              absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2
            `}
        >
            <CloseIcon />
        </motion.span>
    </motion.button>
);
