import { MotionValue, useTransform } from 'motion/react';

const SCROLL_Y_VALUES = [0, 0.33, 0.66, 1];
const ROTATION_LEFT_VALUES = [20, -15, 20, -18];
const ROTATION_RIGHT_VALUES = [-15, 15, -15, 15];

export const useRotation = (scrollY: MotionValue<number>) => {
    const rotationLeft = useTransform(
        scrollY,
        SCROLL_Y_VALUES,
        ROTATION_LEFT_VALUES,
        { clamp: true },
    );

    const rotationRight = useTransform(
        scrollY,
        SCROLL_Y_VALUES,
        ROTATION_RIGHT_VALUES,
        { clamp: true },
    );

    return { rotationLeft, rotationRight };
};
