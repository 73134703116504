'use client';

import { useScroll } from 'motion/react';
import { HTMLAttributes, useRef } from 'react';

import { cn } from '@/utils/cn';

import { ParallaxContext } from './parallax-context';

type Options = Parameters<typeof useScroll>[0];

const DEFAULT_OPTIONS: Options = {
    axis: 'y',
    layoutEffect: false,
    offset: ['start start', 'end start'],
};

/**
 * Parallax container component.
 * Should be used as a container for parallax layers.
 * Renders children wrapped with the parallax context provider.
 *
 * @param options - The options for the useScroll hook from motion/react.
 */
export const ParallaxContainer = ({
    children,
    className,
    options,
    ...props
}: HTMLAttributes<HTMLDivElement> & {
    options?: Options;
}) => {
    const target = useRef<HTMLDivElement>(null);

    return (
        <div
            ref={target}
            className={cn('pointer-events-none relative', className)}
            {...props}
        >
            <ParallaxContext.Provider
                value={{ target, ...DEFAULT_OPTIONS, ...options }}
            >
                {children}
            </ParallaxContext.Provider>
        </div>
    );
};
