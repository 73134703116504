'use client';

import { animate, motion, MotionValue, useMotionValue } from 'motion/react';
import { ReactNode, useRef } from 'react';

import { cn } from '@/utils/cn';

type Props = {
    bottomBoundary?: number;
    children: ReactNode;
    className?: string;
    color: 'green' | 'orange' | 'pink' | 'violet' | 'yellow';
    initialRotate?: number;
    initialX?: number | string;
    initialY?: number | string;
    sticky?: boolean;
    topBoundary?: number;
};

type BadgeProps = {
    bgColor: string;
    children: ReactNode;
    className?: string;
    initialX?: number | string;
    initialY?: number | string;
    onClick: () => void;
    rotate: MotionValue<number | undefined>;
    y: MotionValue<number | string | undefined>;
};

const StickyBadge = ({
    bgColor,
    children,
    className,
    initialX,
    onClick,
    rotate,
    y,
}: BadgeProps) => (
    <motion.div
        className="absolute inline-block"
        style={{ left: initialX, top: y }}
    >
        <motion.span
            className={cn(
                `
                  typography-body4 sticky top-0 z-20 inline-block size-fit
                  cursor-pointer rounded-full px-4 py-2 text-lg font-bold
                  tracking-tighter text-primary-black

                  md:px-5 md:py-3 md:text-xl
                `,
                bgColor,
                className,
            )}
            style={{ rotate }}
            onClick={onClick}
        >
            {children}
        </motion.span>
    </motion.div>
);

const AbsoluteBadge = ({
    bgColor,
    children,
    className,
    initialX,
    initialY,
    onClick,
    rotate,
    y,
}: BadgeProps) => (
    <motion.span
        className={cn(
            `
              typography-body4 absolute inline-block size-fit cursor-pointer
              rounded-full py-2 pl-3 pr-4 font-bold text-primary-black

              md:px-5 md:py-3 md:text-xl md:tracking-tighter
            `,
            bgColor,
            className,
        )}
        initial={{
            right: initialX,
            top: initialY,
        }}
        style={{ rotate, y }}
        onClick={onClick}
    >
        {children}
    </motion.span>
);

const getUnit = (value: string) => {
    const match = value.match(/[%a-z]+$/i);

    return match ? match[0] : null;
};

export const ValueBadge = ({
    bottomBoundary,
    children,
    className,
    color,
    initialRotate,
    initialX,
    initialY,
    sticky,
    topBoundary,
}: Props) => {
    const y = useMotionValue(initialY);
    const rotate = useMotionValue(initialRotate);
    const dropDirection = useRef<'up' | 'down'>('down');

    const bgColor = {
        green: 'bg-primary-green',
        orange: 'bg-primary-orange',
        pink: 'bg-primary-pink',
        violet: 'bg-primary-violet',
        yellow: 'bg-primary-yellow',
    }[color];

    const onClick = () => {
        const previousY = y.get();
        const previousRotate = rotate.get();

        let randomRotate = Math.random() * 18 + 5;
        let randomDrop = Math.random() * 20 + 40;

        if (previousRotate > 0) randomRotate *= -1;
        void animate(rotate, randomRotate);

        if (typeof previousY !== 'number') {
            const currentY = Number(previousY.match(/\d+/g)?.join(''));

            if (currentY > 100) return;

            void animate(y, `${Math.round(currentY) + 2}${getUnit(previousY)}`);

            return;
        }

        if (
            bottomBoundary !== undefined &&
            previousY + randomDrop > bottomBoundary
        )
            dropDirection.current = 'up';
        if (topBoundary !== undefined && previousY - randomDrop < topBoundary)
            dropDirection.current = 'down';

        if (dropDirection.current === 'up') randomDrop *= -1;

        void animate(y, previousY + randomDrop);
    };

    const Badge = sticky ? StickyBadge : AbsoluteBadge;

    return (
        <Badge
            bgColor={bgColor}
            className={className}
            initialX={initialX}
            initialY={initialY}
            onClick={onClick}
            rotate={rotate}
            y={y}
        >
            {children}
        </Badge>
    );
};
