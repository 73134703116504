/**
 * Parallax component.
 * It is a container for parallax layers.
 * Neither the container nor the layers have any styles by default.
 * It can have multiple layers and an optional overlay.
 * The layers will move at different speeds based on the scroll position and the `parallax` value.
 * When the container at the top of the viewport, the layers will be at their initial position.
 * When the container is scrolled out of the viewport, the layers will be at their final position with added `parallax` value.
 * The value can be a number (treated as px) or a string with a CSS unit, i.e. `100px`, `10vh`, `50%`.
 * The overlay is optional will cover the whole container at certain `activationThreshold` value.
 * The overlay is initially hidden.
 * When the container is scrolled to the `activationThreshold`, the overlay will start to fade in.
 * Also exposes a context for the layers to access the scroll position.
 * The context normally should not be used directly, unless you have a custom use case.
 *
 * @example
 *
 * ```tsx
 * <ParallaxContainer>
 *    <ParallaxLayer parallax="50vh" className='h-screen'>
 *       <div>Layer 1</div>
 *   </ParallaxLayer>
 *    <ParallaxLayer parallax="-100%">
 *      <div>Layer 2</div>
 *   </ParallaxLayer>
 *   <ParallaxOverlay />
 * </ParallaxContainer activationThreshold={0.5}>
 */

export { useParallaxScroll } from './hooks/use-parallax-scroll';
export { ParallaxContainer } from '@/components/parallax/components/parallax-container';
export { ParallaxContext } from '@/components/parallax/components/parallax-context';
export { ParallaxImages } from '@/components/parallax/components/parallax-images';
export { ParallaxLayer } from '@/components/parallax/components/parallax-layer';
export { ParallaxOverlay } from '@/components/parallax/components/parallax-overlay';
