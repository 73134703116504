'use client';

import { useScroll } from 'motion/react';
import React, { useRef } from 'react';

import { Cards } from '@/app/(home)/_components/values-section/components/cards';
import { StickySection } from '@/app/(home)/_components/values-section/components/sticky-section';
import { useRotation } from '@/app/(home)/_components/values-section/hooks/use-rotation';
import { Container } from '@/components/container';

export const ValuesSection = () => {
    const ref = useRef(null);

    const { scrollYProgress } = useScroll({
        offset: ['start end', 'end start'],
        target: ref,
    });

    const { rotationLeft, rotationRight } = useRotation(scrollYProgress);

    return (
        <section
            className={`
              h-[1800px] w-full

              lg:h-[1900px]

              md:h-[1850px]
            `}
            ref={ref}
        >
            <Container>
                <StickySection>
                    <Cards
                        rotationRight={rotationRight}
                        rotationLeft={rotationLeft}
                        scrollProgress={scrollYProgress}
                    />
                </StickySection>
            </Container>
        </section>
    );
};
