import { RefObject, useCallback, useEffect, useState } from 'react';

export const useMovementHandler = ({
    disabled,
    distance = '33vh',
    maxAngle = 16,
    maxStepDistance = 0.4,
    minStepDistance = 0.2,
    ref,
}: {
    disabled?: boolean;
    distance?: number | string;
    maxAngle?: number;
    maxStepDistance?: number;
    minStepDistance?: number;
    ref: RefObject<HTMLElement | null>;
}) => {
    const [offset, setOffset] = useState(Math.random() * 2 - 1);
    const [direction, setDirection] = useState(Math.random() > 0.5 ? 1 : -1);
    const [angle, setAngle] = useState((Math.random() * 2 - 1) * maxAngle);

    const handleClick = useCallback(() => {
        if (disabled) return;

        let newOffset =
            offset +
            (minStepDistance +
                Math.random() * (maxStepDistance - minStepDistance)) *
                direction;

        if (Math.abs(newOffset) > 1) {
            setDirection((prevDirection) => (prevDirection === 1 ? -1 : 1));
            newOffset = Math.sign(newOffset);
        }

        setOffset(newOffset);

        setAngle((Math.random() * 2 - 1) * maxAngle);
    }, [
        direction,
        disabled,
        maxAngle,
        maxStepDistance,
        minStepDistance,
        offset,
    ]);

    useEffect(() => {
        if (!ref.current) return;

        const px = typeof distance === 'number' ? 'px' : '';

        ref.current.style.transform = `translateY(calc(${distance}${px}*${offset})) rotate(${angle}deg)`;
    }, [angle, distance, offset, ref]);

    return { handleClick };
};
