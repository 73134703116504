'use client';

import { useViewportSize } from '@mantine/hooks';
import { MotionValue } from 'motion/react';
import { useMemo } from 'react';

import { ValueCard } from '@/app/(home)/_components/values-section/components/value-card';

const cardsData = [
    {
        cardImageSrc: 'values-cards/values-Trust.svg',
        initialPosition: {
            lg: {
                left: 0,
                right: '35%',
                top: '10rem',
            },
            md: {
                left: 0,
                right: '35%',
                top: '10rem',
            },
            sm: {
                left: '10%',
                right: '45%',
                top: '10rem',
            },
        },
        name: 'Trust',
        rotation: 'left',
        zIndex: 0,
    },
    {
        cardImageSrc: 'values-cards/values-Empathy.svg',
        initialPosition: {
            lg: {
                left: '35%',
                right: 0,
                top: '10rem',
            },
            md: {
                left: '55%',
                right: 0,
                top: '17rem',
            },
            sm: {
                left: '25%',
                right: 0,
                top: '26rem',
            },
        },
        name: 'Empathy',
        rotation: 'right',
        zIndex: 0,
    },
    {
        cardImageSrc: 'values-cards/values-Efficiency.svg',
        initialPosition: {
            lg: {
                left: 0,
                right: '50%',
                top: '42rem',
            },
            md: {
                left: 0,
                right: '40%',
                top: '42rem',
            },
            sm: {
                left: 0,
                right: '40%',
                top: '40rem',
            },
        },
        name: 'Efficiency',
        rotation: 'left',
        zIndex: 0,
    },
    {
        cardImageSrc: 'values-cards/values-Ownership.svg',
        initialPosition: {
            lg: {
                left: '45%',
                right: 0,
                top: '42rem',
            },
            md: {
                left: '35%',
                right: 0,
                top: '56rem',
            },
            sm: {
                left: '35%',
                right: 0,
                top: '55rem',
            },
        },
        name: 'Ownership',
        rotation: 'right',
        zIndex: 10,
    },
    {
        cardImageSrc: 'values-cards/values-Positivity.svg',
        initialPosition: {
            lg: {
                left: 0,
                right: 0,
                top: '70rem',
            },
            md: {
                left: 0,
                right: 0,
                top: '76rem',
            },
            sm: {
                left: 0,
                right: 0,
                top: '72rem',
            },
        },
        name: 'Positivity',
        rotation: 'right',
        zIndex: 10,
    },
];

type Props = {
    rotationLeft: MotionValue;
    rotationRight: MotionValue;
    scrollProgress: MotionValue<number>;
};

const getCurrentBreakpointPrefix = (width: number) => {
    if (width >= 1080) return 'lg';
    if (width >= 768) return 'md';
    if (width < 768) return 'sm';

    return 'sm';
};

export const Cards = ({
    rotationLeft,
    rotationRight,
    scrollProgress,
}: Props) => {
    const { width: viewportWidth } = useViewportSize();

    const breakpointPrefix = useMemo(
        () => getCurrentBreakpointPrefix(viewportWidth),
        [viewportWidth],
    );

    return cardsData.map(
        ({ cardImageSrc, initialPosition, name, rotation, zIndex }) => (
            <ValueCard
                key={`${name}-${breakpointPrefix}`}
                rotation={rotation === 'left' ? rotationLeft : rotationRight}
                zIndex={zIndex}
                scrollProgress={scrollProgress}
                name={name}
                initialPosition={initialPosition[breakpointPrefix]}
                cardImageSrc={cardImageSrc}
            />
        ),
    );
};
