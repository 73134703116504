import {
    RefObject,
    useCallback,
    useEffect,
    useLayoutEffect,
    useState,
} from 'react';

import { OFFSET_STRENGTH } from '../constants';

/**
 * This hook calculates the offset that should be applied to images based on the pointer's position.
 * @param radius - The radius of the area in which the pointer should affect the element.
 * @param strength - The strength of the force that attracts the element to the pointer.
 * @param ref - The reference to the element.
 * @returns pointerOffset - The offset of the pointer from the center of the element, percentage-wise.
 */
export const usePointerOffset = ({
    radius = 1500,
    ref,
    strength = OFFSET_STRENGTH.DEFAULT,
}: {
    radius?: number;
    ref: RefObject<HTMLElement | null>;
    strength?: number;
}) => {
    const [pointerCoordinates, setPointerCoordinates] = useState([0, 0]);

    const handlePointerMove = useCallback((event: PointerEvent) => {
        if (event.pointerType === 'touch') return;
        const { clientX, clientY } = event;

        setPointerCoordinates([clientX, clientY]);
    }, []);

    useEffect(() => {
        document.addEventListener('pointermove', handlePointerMove);

        return () => {
            document.removeEventListener('pointermove', handlePointerMove);
        };
    }, [handlePointerMove]);

    const [pointerOffset, setPointerOffset] = useState([0, 0]);

    useLayoutEffect(() => {
        if (!ref.current) return;

        const [cursorX, cursorY] = pointerCoordinates;

        const { height, left, top, width } =
            ref.current.getBoundingClientRect();

        const center = [left + width / 2, top + height / 2];

        const distance = Math.hypot(cursorX - center[0], cursorY - center[1]);

        const angle = Math.atan2(cursorY - center[1], cursorX - center[0]);

        // The force should attract the element to the cursor, but decay with the distance.
        const force = (-strength * Math.min(distance, radius)) / radius;

        setPointerOffset([force * Math.cos(angle), force * Math.sin(angle)]);
    }, [strength, pointerCoordinates, radius, ref]);

    return {
        pointerOffset,
    };
};
