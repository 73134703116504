import { HTMLAttributes } from 'react';

import { cn } from '@/utils/cn';

export const HeroSectionContainer = ({
    children,
    className,
    ...props
}: HTMLAttributes<HTMLDivElement>) => (
    <section
        className={cn(
            `relative min-h-dvh w-full select-none overflow-hidden`,
            className,
        )}
        {...props}
    >
        {children}
    </section>
);
