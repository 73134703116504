'use client';

import { useViewportSize } from '@mantine/hooks';
import { motion } from 'motion/react';
import React, { useMemo } from 'react';

import { AnimatedBottomText } from '@/app/(home)/_components/values-section/components/animated-bottom-text';
import { HeaderTextContainer } from '@/app/(home)/_components/values-section/components/header-text-container';

type StickySectionProps = {
    children: React.ReactNode;
};

const getSectionHeight = (width: number) => {
    if (width >= 1080) return 920;
    if (width >= 768) return 1100;
    if (width < 768) return 1200;

    return 920;
};

export const StickySection = ({ children }: StickySectionProps) => {
    const { width: viewportWidth } = useViewportSize();

    const sectionHeight = useMemo(
        () => getSectionHeight(viewportWidth),
        [viewportWidth],
    );

    return (
        <section
            className={`relative z-10 mb-40 w-full pb-20`}
            id="values-sticky-section"
        >
            <motion.div
                className={'relative z-10'}
                style={{
                    height: sectionHeight,
                }}
            >
                <>
                    <motion.div className={'sticky top-48 z-10'}>
                        <HeaderTextContainer />
                    </motion.div>

                    {children}
                </>
            </motion.div>
            <AnimatedBottomText />
        </section>
    );
};
