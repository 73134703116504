'use client';

import { motion, MotionConfig } from 'motion/react';
import { ReactNode } from 'react';

import { cn } from '@/utils/cn';

type HeroText = {
    className: string;
    content: ReactNode[];
    delayBetweenChildrenAnimation?: number;
    delayStartAnimation?: number;
    durationChildAnimation?: number;
    shouldStartAnimation?: boolean;
    tagName?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'div' | 'span';
};

const variants = {
    animate: { opacity: 1, y: 0 },
    initial: { opacity: 0.4, y: 300 },
};

export const HeroText = ({
    className,
    content,
    delayBetweenChildrenAnimation = 0.4,
    delayStartAnimation = 0.4,
    durationChildAnimation = 0.5,
    shouldStartAnimation = true,
    tagName = 'h1',
}: HeroText) => {
    const MotionElement = motion[tagName];

    return (
        <MotionElement
            initial="initial"
            animate={shouldStartAnimation ? 'animate' : 'initial'}
            transition={{
                delayChildren: delayStartAnimation,
                staggerChildren: delayBetweenChildrenAnimation,
            }}
            className={cn(
                className,
                `
                  w-full gap-0 overflow-hidden text-center

                  [&_span]:inline-block
                `,
            )}
        >
            <MotionConfig
                transition={{
                    duration: durationChildAnimation,
                    ease: 'easeOut',
                }}
            >
                {content.map((element, index) =>
                    typeof element === 'string' ? (
                        <div
                            key={element}
                            className={cn(
                                {
                                    '-mt-10 max-md:-pt-4 md:-pt-12':
                                        index !== 0,
                                },
                                `inline-block overflow-y-clip`,
                            )}
                        >
                            <motion.span variants={variants}>
                                {element}
                            </motion.span>
                        </div>
                    ) : (
                        element
                    ),
                )}
            </MotionConfig>
        </MotionElement>
    );
};
