import { useTransform } from 'motion/react';

import { useParallaxScroll } from '@/components/parallax/hooks/use-parallax-scroll';

const DEFAULT_PROPS: {
    parallax?: number | string;
} = {
    parallax: 0,
};

/**
 * This hook applies a parallax effect to the element.
 * The element will move vertically based on the scrollYProgress and the parallax value.
 *
 * @param parallax - The parallax value. It can be a number or a string with a CSS unit. Default is 0.
 * The value will be multiplied by the scrollYProgress to calculate the transform value.
 *
 * @returns ref - The reference to the element.
 */
export const useParallaxTransform = ({ parallax } = DEFAULT_PROPS) => {
    const { scrollYProgress } = useParallaxScroll();

    const unit = typeof parallax === 'number' ? 'px' : '';

    const y = useTransform(
        scrollYProgress,
        (value) => `calc(${-value} * ${parallax}${unit})`,
    );

    return {
        y,
    };
};
