'use client';

import { useScroll } from 'motion/react';
import { createContext } from 'react';

/**
 * Parallax context.
 * Container the parameters for the useScroll hook from motion/react,
 * including target which is a ref to the Parallax.Container component.
 * It is used internally by the `Parallax.Layer` component.
 * Normally should not be used directly, unless you have a custom use case.
 */

export const ParallaxContext = createContext<Parameters<typeof useScroll>[0]>(
    {},
);
