import { useScroll } from 'motion/react';
import { useContext } from 'react';

import { ParallaxContext } from '../components/parallax-context';

/**
 *
 * @returns The useScroll hook from motion/react with params form the ParallaxContext.
 */
export const useParallaxScroll = () => useScroll(useContext(ParallaxContext));
