import { useMemo } from 'react';

import { getPseudoRandom } from '@/utils/get-pseudo-random';

/**
 * This hook returns the layout offset values, which are pseudo-random and are calculated based on the input string.
 * The values are the percentage of the image width and height.
 * @param amountX - The amount of offset on the x-axis
 * @param amountY - The amount of offset on the y-axis
 * @param input - The input string to generate the pseudo-random values.
 * @returns
 */
export const useLayoutOffset = ({
    amountX = 0.1,
    amountY = 0.1,
    input,
}: {
    amountX?: number;
    amountY?: number;
    input?: string;
}) => {
    const layoutOffset = useMemo(() => {
        if (!input) {
            return [0, 0];
        }

        const fileName = input.split('/').pop() ?? input;

        const values = [
            fileName.slice(0, fileName.length / 2),
            fileName.slice(fileName.length / 2),
        ]
            .map((value) => getPseudoRandom(value))
            .map((value) => value * 2 - 1);

        return [values[0] * amountX, values[1] * amountY];
    }, [amountX, amountY, input]);

    return { layoutOffset };
};
