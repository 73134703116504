import { motion } from 'motion/react';
import { PropsWithChildren } from 'react';

import { useMenuHide } from '@/components/header/hooks/use-menu-hide';

export const HidableContainer = ({ children }: PropsWithChildren) => {
    const y = useMenuHide({ headerHeight: 148 });

    return (
        <>
            {/* This div is a placeholder to keep space for fixed element. */}
            <div className="min-w-[720px]" />
            <nav className={`fixed left-1/2 z-50 -translate-x-1/2`}>
                <motion.ul
                    style={{ y }}
                    className={`
                      flex items-center gap-1 rounded-full border
                      border-primary-white bg-primary-black p-2
                    `}
                >
                    {children}
                </motion.ul>
            </nav>
        </>
    );
};
