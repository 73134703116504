import { ComponentProps } from 'react';

import { Badge } from '@/components/badge';
import { ParallaxLayer } from '@/components/parallax';
import { cn } from '@/utils/cn';

const BADGES = [
    {
        color: 'pink',
        parallax: '0',
        text: '#BeKind',
    },
    {
        color: 'yellow',
        parallax: '20lvh',
        text: '#SpreadGoodVibes',
    },
    {
        color: 'orange',
        parallax: '10lvh',
        text: '#WorkSmart',
    },
    {
        color: 'green',
        parallax: '40lvh',
        text: '#GotYourBack',
    },
    {
        color: 'violet',
        parallax: '30lvh',
        text: '#BeProud',
    },
] as const satisfies Array<
    Pick<ComponentProps<typeof Badge>, 'color'> & {
        parallax: string;
        text: string;
    }
>;

export const BadgesLayers = ({
    className,
    ...props
}: ComponentProps<typeof ParallaxLayer>) => (
    <>
        {BADGES.map(({ color, parallax, text }, index) => (
            <ParallaxLayer
                key={index}
                parallax={parallax}
                className={cn(`absolute inset-0`, className)}
                {...props}
            >
                <Badge
                    color={color}
                    className={`top-1/2`}
                    style={{
                        left: `${15 + ((100 - 30) * index) / (BADGES.length - 1)}%`,
                    }}
                    typingEffectConfig={{
                        delay:
                            1000 +
                            500 *
                                Math.cos(
                                    (Math.PI * index) / (BADGES.length - 1),
                                ),
                        maxSpeed: 0,
                        minSpeed: 0,
                    }}
                    movementConfig={{
                        distance: '28vh',
                    }}
                >
                    {text}
                </Badge>
            </ParallaxLayer>
        ))}
    </>
);
