/**
 * Returns a pseudo-random value between 0 and 1 based on the given seed string.
 * This function exists to provide a deterministic way to generate repetitive pseudo-random values,
 * which can be useful React components that need to generate the same pseudo-random values on each render, or in different environments.
 *
 * @param seed - The seed string to generate the pseudo-random value.
 * @returns The pseudo-random value between 0 and 1.
 */
export const getPseudoRandom = (seed?: string) => {
    if (!seed) {
        return 0;
    }

    let sum = 0;

    for (const char of seed) {
        sum += char.codePointAt(0) ?? 0;
    }

    return (Math.sin(sum) + 1) * 0.5;
};
