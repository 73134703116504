import { useEffect, useMemo, useRef, useState } from 'react';

export const useTypingEffect = ({
    delay = 0,
    disabled,
    maxSpeed = 200,
    minSpeed = 10,
    text,
}: {
    delay?: number;
    disabled?: boolean;
    maxSpeed?: number;
    minSpeed?: number;
    text: string;
}) => {
    const [currentText, setCurrentText] = useState('');

    const timeoutRef = useRef<ReturnType<typeof setTimeout>>(undefined);

    useEffect(() => {
        if (disabled) return;

        const type = async () => {
            clearTimeout(timeoutRef.current);

            for (let i = 0; i < text.length; i++) {
                await new Promise<void>((resolve) => {
                    timeoutRef.current = setTimeout(
                        () => {
                            setCurrentText(text.slice(0, i + 1));
                            resolve();
                        },
                        Math.random() * (maxSpeed - minSpeed) + minSpeed,
                    );
                });
            }
        };

        timeoutRef.current = setTimeout(() => {
            void type();
        }, delay);

        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [delay, disabled, maxSpeed, minSpeed, text]);

    const isComplete = useMemo(() => currentText === text, [currentText, text]);

    return { currentText, isComplete };
};
