'use client';

import {
    motion,
    MotionValue,
    Target,
    useTransform,
    VariantLabels,
} from 'motion/react';
import Image from 'next/image';

import { cn } from '@/utils/cn';

interface Props {
    cardImageSrc: string;
    initialPosition: Target | VariantLabels;
    name: string;
    rotation: MotionValue;
    scrollProgress: MotionValue<number>;
    zIndex?: number;
}

const useParallax = (value: MotionValue<number>, distance: number) =>
    useTransform(value, [1, 0], [-distance, distance]);

export const ValueCard = ({
    cardImageSrc,
    initialPosition,
    name,
    rotation,
    scrollProgress,
    zIndex,
}: Props) => {
    const y = useParallax(scrollProgress, 400);
    const z = `z-${zIndex}`;

    return (
        <motion.div
            key={name}
            className={cn(
                `
                  absolute mx-auto inline-block size-fit max-h-[265px]
                  max-w-[200px]

                  lg:max-w-[350px]

                  md:max-w-[280px]

                  sm:max-w-[200px]
                `,
                z,
            )}
            initial={initialPosition}
            style={{ rotate: rotation, y }}
        >
            <Image
                src={cardImageSrc}
                width={350}
                height={420}
                alt={name}
            />
        </motion.div>
    );
};
