import { useCallback, useState } from 'react';

/**
 * Hook that provides functions to detect and control the orientation of an image.
 * It should detect the orientation of the image, ideally on load,
 * and provide functions to expand and shrink the image.
 * The expanded image has same currentlyWide value as the natural orientation of the image,
 * while the shrunk image has the opposite value.
 *
 * @returns detectOrientation - A function that detects the orientation of the image.
 * @returns expand - A function that expands the image.
 * @returns shrink - A function that shrinks the image.
 * @returns isCurrentlyWide - A boolean that indicates if the image is currently wide.
 */
export const useImageSize = () => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggle = useCallback(() => {
        setIsExpanded((prev) => !prev);
    }, []);

    return {
        isExpanded,
        toggle,
    };
};
