'use client';

import { motion } from 'motion/react';
import Image from 'next/image';

import { BadgesLayers } from '@/components/badge';
import { HeroSectionContainer } from '@/components/hero-section-container';
import { HeroText } from '@/components/hero-text';
import {
    ParallaxContainer,
    ParallaxLayer,
    ParallaxOverlay,
} from '@/components/parallax';

const content = [
    'Swing',
    <br
        key="br1"
        className="lg:hidden"
    />,
    <span key="space">&nbsp;</span>,
    'into',
    <br key="br2" />,
    'action',
];

const MotionImage = motion.create(Image);

export const HeroSection = () => (
    <HeroSectionContainer>
        <ParallaxContainer className="h-svh">
            <ParallaxLayer
                parallax={'-20lvh'}
                className={`absolute inset-0 flex items-center justify-center`}
            >
                <HeroText
                    content={content}
                    delayBetweenChildrenAnimation={0.4}
                    delayStartAnimation={0}
                    durationChildAnimation={0.3}
                    className={`typography-hero-1`}
                />
            </ParallaxLayer>

            <BadgesLayers />

            <ParallaxLayer
                className="absolute inset-0"
                parallax="-50lvh"
            >
                <MotionImage
                    src="home/arrow-down.svg"
                    width={56}
                    height={56}
                    alt="Arrow down to next section"
                    className={`absolute bottom-[35px] left-1/2 cursor-pointer`}
                    onClick={() => {
                        window.scrollTo({
                            behavior: 'smooth',
                            top: window.innerHeight + 80,
                        });
                    }}
                    animate={{
                        y: [0, -10],
                    }}
                    initial={{
                        x: '-50%',
                    }}
                    transition={{
                        duration: 1.2,
                        repeat: Number.POSITIVE_INFINITY,
                        repeatType: 'reverse',
                    }}
                />
            </ParallaxLayer>
            <ParallaxOverlay />
        </ParallaxContainer>
    </HeroSectionContainer>
);
