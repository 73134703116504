'use client';

import { AnimatePresence, motion } from 'motion/react';

import { ButtonHippoCompany } from '@/components/button-hippo-company/button-hippo-company';
import { ButtonMenu } from '@/components/button-menu';
import { ButtonMenuCTA } from '@/components/button-menu-cta';
import { Container } from '@/components/container';
import { HOME_LINK, LINKS, OPEN_POSITION_LINK } from '@/config/routes';

import { SwingdevLogoResponsive } from '../components/swingdev-logo-responsive';
import { useHomeHide } from '../hooks/use-home-hide';
import { HeaderProps } from '../types';
import { HidableContainer } from './components/hidable-container/hidable-container';

export const HeaderDesktop = ({ openPositionsNumber }: HeaderProps) => {
    const isHomeLinkHidden = useHomeHide();

    return (
        <header
            className={`
              relative z-30 mb-[calc(var(--header-desktop-height)*-1)] hidden
              h-[var(--header-desktop-height)]

              xl:block
            `}
        >
            <Container className="flex h-full items-center justify-between">
                <SwingdevLogoResponsive />

                <HidableContainer>
                    <AnimatePresence>
                        {!isHomeLinkHidden && (
                            <motion.li
                                key={`${HOME_LINK.title}-${HOME_LINK.href}`}
                                className="overflow-hidden"
                                animate={{
                                    width: isHomeLinkHidden ? 0 : 'auto',
                                }}
                                transition={{ duration: 0.1 }}
                                exit={{ width: 10 }}
                            >
                                <ButtonMenu
                                    href={HOME_LINK.href}
                                    title={HOME_LINK.title}
                                />
                            </motion.li>
                        )}
                    </AnimatePresence>
                    {LINKS.map(({ href, title }) => (
                        <li key={`${title}-${href}`}>
                            <ButtonMenu
                                href={href}
                                title={title}
                                isStateVisible
                            />
                        </li>
                    ))}
                    <li>
                        <ButtonMenuCTA
                            href={OPEN_POSITION_LINK.href}
                            title={OPEN_POSITION_LINK.title}
                            count={openPositionsNumber}
                        />
                    </li>
                </HidableContainer>

                <ButtonHippoCompany />
            </Container>
        </header>
    );
};
