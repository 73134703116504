'use client';

import { motion } from 'motion/react';
import { ComponentProps } from 'react';

import { cn } from '@/utils/cn';

import { useParallaxTransform } from '../hooks/use-parallax-transform';

/**
 * Parallax layer component.
 *
 * @param parallax
 * The parallax value that will be applied to the layer. It can be a number (treated as px) or a string with a unit.
 * The value will be multiplied by the scrollYProgress and applied as a translateY transform to the layer.
 */
export const ParallaxLayer = ({
    children,
    className,
    parallax = 0,
    style: _style,
    ...props
}: ComponentProps<typeof motion.div> &
    Parameters<typeof useParallaxTransform>[0]) => {
    const { y } = useParallaxTransform({ parallax });

    return (
        <motion.div
            className={cn(
                `pointer-events-none will-change-transform`,
                className,
            )}
            style={{ y }}
            {...props}
        >
            {children}
        </motion.div>
    );
};
