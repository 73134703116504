import Link from 'next/link';

import { HippoCompanyLogo } from './assets/hippo-company-logo';

export const ButtonHippoCompany = () => (
    <Link
        href="https://hippo.com"
        className={`
          rounded-full px-5 py-4 transition-colors

          hover:bg-primary-green hover:text-primary-black
        `}
    >
        <HippoCompanyLogo />
    </Link>
);
