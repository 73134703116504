'use client';

import { Route } from 'next';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { cn } from '@/utils/cn';

type Props = {
    count: number;
    href: Route;
    title: string;
};

export const ButtonMenuCTA = ({ count, href, title }: Props) => {
    const pathname = usePathname();
    const isActive = pathname === href;

    return (
        <Link
            key={href}
            href={href}
            className={cn(
                `
                  group typography-body3 relative inline-block whitespace-nowrap
                  rounded-full bg-primary-white px-5 py-3 font-medium
                  text-primary-black transition-colors duration-300 ease-in-out

                  hover:bg-primary-limegreen hover:text-primary-black
                `,
                {
                    'bg-primary-limegreen text-primary-black': isActive,
                },
            )}
        >
            {title}
            {count > 0 && (
                <span
                    role="status"
                    aria-label={`${title} count: ${count}`}
                    className={cn(
                        `
                          absolute -right-1 -top-1 flex size-6 select-none
                          items-center justify-center rounded-full
                          bg-primary-limegreen text-sm text-primary-black
                          transition-colors duration-300 ease-in-out

                          group-hover:bg-primary-white
                          group-hover:text-primary-black
                        `,
                        {
                            'bg-primary-white text-primary-black': isActive,
                        },
                    )}
                >
                    {count}
                </span>
            )}
        </Link>
    );
};
