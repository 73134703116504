import { motion } from 'motion/react';
import { PropsWithChildren } from 'react';

import { useMenuHide } from '@/components/header/hooks/use-menu-hide';
import { cn } from '@/utils/cn';

type Props = PropsWithChildren & {
    isExpanded: boolean;
};

export const HidableContainer = ({ children, isExpanded }: Props) => {
    const y = useMenuHide({ headerHeight: 108, isLocked: isExpanded });

    return (
        <motion.header
            style={{ y }}
            className={cn(
                `
                  fixed z-50 flex w-full flex-col

                  xl:hidden
                `,
                { 'h-screen': isExpanded },
            )}
        >
            {children}
        </motion.header>
    );
};
