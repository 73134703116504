'use client';

import { useEffect, useState } from 'react';

import { SwingdevLogoResponsive } from '../components/swingdev-logo-responsive';
import { HeaderProps } from '../types';
import { AnimatedOverlay } from './components/animated-overlay';
import { ButtonExpandMenu } from './components/button-expand-menu';
import { HidableContainer } from './components/hidable-container';
import { Navigation } from './components/navigation';
import { TopBar } from './components/top-bar/top-bar';

export const HeaderMobile = ({ openPositionsNumber }: HeaderProps) => {
    const [isExpanded, setIsExpanded] = useState(false);

    // Prevent body scrolling when menu is expanded
    useEffect(() => {
        document.body.style.overflow = isExpanded ? 'hidden' : 'unset';
    }, [isExpanded]);

    const handleToggle = () => {
        setIsExpanded((prev) => !prev);
    };

    return (
        <>
            <HidableContainer isExpanded={isExpanded}>
                <AnimatedOverlay isExpanded={isExpanded} />
                <TopBar isExpanded={isExpanded}>
                    <SwingdevLogoResponsive />
                    <ButtonExpandMenu
                        isExpanded={isExpanded}
                        onToggle={handleToggle}
                    />
                </TopBar>
                <Navigation
                    isExpanded={isExpanded}
                    openPositionsNumber={openPositionsNumber}
                    onToggle={handleToggle}
                />
            </HidableContainer>
        </>
    );
};
