import { ButtonMenu } from '@/components/button-menu';
import { LINKS } from '@/config/routes';

export const FooterNavigation = () => (
    <ul
        className={`
          flex flex-col

          md:flex-row
        `}
    >
        {LINKS.map(({ href, title }) => (
            <li key={href}>
                <ButtonMenu
                    href={href}
                    title={title}
                />
            </li>
        ))}
    </ul>
);
