import { cn } from '@/utils/cn';

import { IconProps } from './types';

export const Instagram = ({ className }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="57"
        height="56"
        viewBox="0 0 57 56"
        fill="none"
        className={cn('stroke-primary-white', className)}
    >
        <path
            d="M35.7326 20.5992H35.7486M33.1327 27.2444C33.2808 28.2431 33.1102 29.263 32.6452 30.1592C32.1802 31.0553 31.4444 31.7821 30.5426 32.236C29.6408 32.6899 28.6188 32.8479 27.622 32.6875C26.6252 32.5271 25.7044 32.0565 24.9905 31.3426C24.2766 30.6286 23.8059 29.7078 23.6455 28.711C23.4851 27.7142 23.6431 26.6922 24.097 25.7904C24.551 24.8886 25.2777 24.1529 26.1738 23.6879C27.07 23.2229 28.09 23.0523 29.0887 23.2004C30.1074 23.3514 31.0505 23.8261 31.7787 24.5543C32.5069 25.2825 32.9816 26.2257 33.1327 27.2444ZM22.333 16H34.333C37.6467 16 40.333 18.6863 40.333 22V34C40.333 37.3137 37.6467 40 34.333 40H22.333C19.0193 40 16.333 37.3137 16.333 34V22C16.333 18.6863 19.0193 16 22.333 16Z"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
