import { ButtonHippoCompany } from '@/components/button-hippo-company/button-hippo-company';
import { ButtonMenu } from '@/components/button-menu';
import { ButtonMenuCTA } from '@/components/button-menu-cta';
import { HOME_LINK, LINKS, OPEN_POSITION_LINK } from '@/config/routes';

import { Container } from './components/container';
import { FadingItem } from './components/fading-item';

type Props = {
    isExpanded: boolean;
    onToggle: () => void;
    openPositionsNumber: number;
};

const LINKS_ALL = [HOME_LINK, ...LINKS];

export const Navigation = ({
    isExpanded,
    onToggle,
    openPositionsNumber,
}: Props) => {
    if (!isExpanded) return null;

    return (
        <Container>
            {LINKS_ALL.map(({ href, title }, i) => (
                <FadingItem
                    key={`${href}-${title}`}
                    delay={i / 20}
                    onToggle={onToggle}
                >
                    <ButtonMenu
                        href={href}
                        title={title}
                    />
                </FadingItem>
            ))}

            <FadingItem
                delay={0.3}
                onToggle={onToggle}
                className="mt-4"
            >
                <ButtonMenuCTA
                    href={OPEN_POSITION_LINK.href}
                    title={OPEN_POSITION_LINK.title}
                    count={openPositionsNumber}
                />
            </FadingItem>

            <FadingItem
                delay={0.4}
                onToggle={onToggle}
                className="mb-8 mt-auto"
            >
                <ButtonHippoCompany />
            </FadingItem>
        </Container>
    );
};
