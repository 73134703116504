import { cn } from '@/utils/cn';

import { IconProps } from './types';

export const LinkedIn = ({ className }: IconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="57"
        height="56"
        viewBox="0 0 57 56"
        fill="none"
        className={cn('stroke-primary-white', className)}
    >
        <path
            d="M22.333 25V34.6M34.333 34.6V29.8C34.333 27.4804 32.4526 25.6 30.133 25.6C27.8134 25.6 25.933 27.4804 25.933 29.8V34.6V25M21.733 22H22.933M17.533 16H39.133C39.7957 16 40.333 16.5373 40.333 17.2V38.8C40.333 39.4627 39.7957 40 39.133 40H17.533C16.8703 40 16.333 39.4627 16.333 38.8V17.2C16.333 16.5373 16.8703 16 17.533 16Z"
            strokeWidth="2"
        />
    </svg>
);
