'use client';

import { Route } from 'next';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { cn } from '@/utils/cn';

type Props = {
    href: Route;
    isStateVisible?: boolean;
    title: string;
};

export const ButtonMenu = ({ href, isStateVisible, title }: Props) => {
    const pathname = usePathname();
    const isActive = pathname === href;

    return (
        <Link
            key={href}
            href={href}
            className={cn(
                `
                  typography-body2 inline-block whitespace-nowrap rounded-full
                  border px-5 py-3 font-medium transition-colors duration-300
                  ease-in-out

                  hover:border-transparent hover:bg-primary-limegreen
                  hover:text-primary-black
                `,
                isActive && isStateVisible
                    ? 'border-primary-white'
                    : 'border-transparent',
            )}
        >
            {title}
        </Link>
    );
};
