'use client';

import { useMotionValueEvent } from 'motion/react';
import { HTMLAttributes, useRef } from 'react';

import { useParallaxScroll } from '@/components/parallax';
import { cn } from '@/utils/cn';

/**
 * Renders an overlay over parallax container.
 * The overlay is initially hidden.
 * The overlay will fade in when the container is scrolled to the activation threshold.
 * The overlay will cover the whole container.
 *
 * @param activationThreshold - The scroll threshold value at which the overlay will start to fade in.
 */
export const ParallaxOverlay = ({
    activationThreshold = 2 / 3,
    children,
    className,
    ...props
}: HTMLAttributes<HTMLDivElement> & {
    activationThreshold?: number;
}) => {
    const { scrollYProgress } = useParallaxScroll();

    const container = useRef<HTMLDivElement>(null);

    useMotionValueEvent(scrollYProgress, 'change', (value) => {
        if (!container.current) return;

        container.current.style.opacity =
            value < activationThreshold
                ? '0'
                : `${(value - activationThreshold) / (1 - activationThreshold)}`;
    });

    return (
        <div
            ref={container}
            className={cn(
                `
                  pointer-events-none absolute inset-0 bg-primary-black
                  opacity-0 transition-opacity duration-300 ease-out
                `,
                className,
            )}
            {...props}
        >
            {children}
        </div>
    );
};
