import * as motion from 'motion/react-client';
import Image from 'next/image';

import { cn } from '@/utils/cn';

import { AnimatedImageProps } from '../../types';

const oneImage = 'rounded-full object-cover size-full';
const threeImages =
    'size-full rounded-[27px] md:rounded-[50px] lg:rounded-[27px] xl:rounded-[50px]';

const variantConfig = {
    circle: {
        animate: { clipPath: 'circle(50%)' },
        className: oneImage,
        initial: { clipPath: 'circle(0%)' },
    },
    rectangle: {
        animate: { clipPath: 'inset(0% round var(--border-round))' },
        className: threeImages,
        initial: { clipPath: 'inset(50% round 27px)' },
    },
};

export const AnimatedImage = ({
    alt,
    isInView,
    variant,
    ...image
}: AnimatedImageProps) => {
    const { animate, className, initial } = variantConfig[variant];

    return (
        <motion.div
            className={cn(
                `
                  [--border-round:27px]

                  last-of-type:col-span-2

                  lg:[--border-round:27px]

                  md:[--border-round:50px]

                  xl:[--border-round:50px]
                `,
            )}
            initial="initial"
            animate={isInView && 'animate'}
            variants={{
                animate,
                initial,
            }}
            transition={{
                delay: 0.1,
                duration: 0.7,
                transition: 'spring',
            }}
        >
            <Image
                {...image}
                alt={alt}
                className={cn(className)}
                priority={true}
                // native image size on page is max 604px
                width={604}
                height={604}
            />
        </motion.div>
    );
};
