import { useMotionValueEvent, useScroll } from 'motion/react';
import { PropsWithChildren, useState } from 'react';

import { Container } from '@/components/container';
import { cn } from '@/utils/cn';

const MIN_SCROLL_Y = 30;

export const TopBar = ({
    children,
    isExpanded,
}: PropsWithChildren & {
    isExpanded?: boolean;
}) => {
    const { scrollY } = useScroll();

    const [hasBackground, setHasBackground] = useState(
        scrollY.get() > MIN_SCROLL_Y,
    );

    useMotionValueEvent(scrollY, 'change', (value) =>
        setHasBackground(value > MIN_SCROLL_Y),
    );

    const isTransparent = !hasBackground || isExpanded;

    return (
        <Container
            className={cn(
                `
                  z-10 flex min-h-[108px] w-full items-center justify-between
                  gap-4 transition-colors duration-300
                `,
                {
                    'bg-primary-black': !isTransparent,
                    'bg-transparent': isTransparent,
                },
            )}
        >
            {children}
        </Container>
    );
};
